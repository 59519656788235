import React from 'react';
import Label from '../typography/Label';
import { useNavigate, useLocation } from 'react-router-dom';
import imgLogo from '../../assets/images/logo.png';
import { MaterialSymbol } from 'react-material-symbols';
import { useAuth } from '../../App'; // Certifique-se de que o caminho está correto

const SidebarItem = ({icon, text, path, onClick}) => {
  const location = useLocation();
  const isActive = location.pathname === path;

  return (
    <div className='flex gap py-sm gap-xxs group cursor-pointer w-full' onClick={onClick}>
      <MaterialSymbol
        icon={icon}
        size={16} 
        grade={-25}
        color={isActive ? '#E0AA4F' : '#FBFCF8'}
        className='group-hover:opacity-50'
      />
      <Label style={`group-hover:opacity-50 ${isActive ? 'text-yellow-2' : 'text-white-1'}`}>{text}</Label>
    </div>
  )
}

const Sidebar = () => {
  const { logout } = useAuth(); // Obtenha a função logout do contexto de autenticação
  const navigate = useNavigate(); 

  return (
    <div className='flex flex-col w-[240px] h-screen bg-green-4 rounded-r-lg px-lg pt-xl pb-md gap-lg shrink-0'>
        <img className='h-xl object-contain object-left' src={imgLogo} alt="Logo" style={{imageRendering: 'pixelated'}} draggable="false"/>
        <div className='flex grow flex-col items-start'>
          <SidebarItem
            icon="Home"
            text="Dashboard"
            onClick={() => navigate('/')}
            path={('/')}
          />
          <SidebarItem
            icon="chat_bubble"
            text="Chats"
            onClick={() => navigate('/chats')}
            path={('/chats')}
          />
          <SidebarItem
            icon="calendar_month"
            text="Agenda"
            onClick={() => navigate('/appointments')}
            path={('/appointments')}
          />
          <SidebarItem
            icon="user_attributes"
            text="Atendimentos"
            onClick={() => navigate('/services')}
            path={('/services')}
          />
          <SidebarItem
            icon="group"
            text="Administradores e técnicos"
            onClick={() => navigate('/users')}
            path={('/users')}
          />
          <SidebarItem
            icon="location_away"
            text="Propriedades e beneficiários"
            onClick={() => navigate('/properties')}
            path={('/properties')}

          />
          <SidebarItem
            icon="menu_book"
            text="Tutoriais"
            onClick={() => navigate('/contents')}
            path={('/contents')}

          />
          {/*<SidebarItem
            icon="monitoring"
            text="Relatórios"
          /> */}
        </div>
        <SidebarItem
          icon="logout"
          text="Sair"
          onClick={logout}
        />
    </div>
  );
};

export default Sidebar;
