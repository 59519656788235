import React, { useState, useEffect} from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Display from '../../components/typography/Display';
import ButtonPrimary from '../../components/actions/ButtonPrimary';
import ButtonLink from '../../components/actions/ButtonLink';
import Title from '../../components/typography/Title';
import Body from '../../components/typography/Body';
import Label from '../../components/typography/Label';
import Table from '../../components/layout/Table';
import Modal from '../../components/overlay/Modal';
import ContentField from '../../components/fragments/ContentField';
import FormError from '../../components/inputs/FormError';

import { getAppointmentReferences, downloadAppointmentsCSV, getAttestById, fetchAttestPhoto, putAppointmentCancel } from '../../api/appointments';
import { getUser } from '../../api/users';
import { getAppointments } from '../../api/appointments';

const Appointments = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [references, setReferences] = useState();
  const [searchTerm, setSearchTerm] = useState();
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [selectedAppointmentData, setSelectedAppointmentData] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const loadAppointments = async ({page, limit, search = searchTerm}) => {
    const result = await getAppointments({ 
      page,
      limit,
      search ,
      orderBy: 'appointmentScheduledDate',
      orderDirection: 'desc'
    });

    result.data = result.data.map(appointment => ({
      ...appointment,
      producerNameOrNickname: appointment.producer.user.nickname || appointment.producer.user.name,
      openedByUserNameOrNickname: appointment.openedByUser.nickname || appointment.openedByUser.name,
      scheduledTo: new Date(appointment.scheduledTo).toLocaleString('pt-BR', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      }),      
    }))

    setData(result.data);
    setTotalItems(result.links.metaData.total);
    setTotalPages(result.links.metaData.lastPage);
    setIsTableLoading(false);
  };

  const loadReferences = async () => {
    const response = await getAppointmentReferences();
    setReferences(response.references)
  }

  const columns = [
    { Header: 'Produtor', accessor: 'producerNameOrNickname' },
    { Header: 'Propriedade', accessor: 'propertyAlias' },
    { Header: 'Técnico', accessor: 'openedByUserNameOrNickname'},
    { Header: 'Data', accessor: 'scheduledTo' },
    { Header: 'Status', accessor: 'status', type: 'tag' }
  ];

  const actions = [
    { label: 'Ver', onClick: (row) => openEditModal(row) },
  ];

  const handleSearch = async (term) => {
    setSearchTerm(term);
    setCurrentPage(1);
    await loadAppointments({page: 1, search: term});
  };
  
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  
  const handleDownloadCSV = async () => {
    const csvData1 = await downloadAppointmentsCSV({ search: searchTerm });
    if (csvData1) {
      const blob = new Blob([csvData1], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'appointments.csv';
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  const openEditModal = async (row) => {
    setSelectedAppointmentData(row);
    setIsEditModalOpen(true);
  };
  
  const closeModal = () => {
    setIsEditModalOpen(false);
    setSelectedAppointmentData(null);
  };

  const reloadTable = () => {
    setIsTableLoading(true);
    if (!references) loadReferences();
    if (references) loadAppointments({ page: currentPage });
  };

  useEffect(() => {
    setIsTableLoading(true);
    if (!references) loadReferences()
    if (references) loadAppointments({page: currentPage});
  }, [currentPage, references]);

  useEffect(() => {
    if (setSelectedAppointmentData) setIsEditModalOpen(true)
  }, [setSelectedAppointmentData])

  return (
    <div className="flex flex-col w-full h-max p-xl gap-lg">
      <div className='flex justify-between'>
        <Display size='md' style="text-green-4">Agenda</Display>
        <div className='flex gap-xs'>
          {/* <ButtonPrimary
            icon='calendar_add_on'
            text='Nova visita'
            left
          /> */}
          {/* <ButtonPrimary
            icon='calendar_month'
            left
          /> */}
          <ButtonPrimary
            icon='download'
            left
            onClick={handleDownloadCSV}
          />
        </div>
      </div>
      <div className='flex flex-col gap-sm'>

        {isTableLoading ? (
          <div className="flex items-center justify-center h-full">
            <span>Carregando...</span>
          </div>
        ) : (
          <Table
            columns={columns}
            data={data}
            actions={actions}
            currentPage={currentPage}
            totalPages={totalPages}
            totalItems={totalItems}
            onPageChange={handlePageChange}
            onSearch={handleSearch}
            defaultFilterValue=""
            searchTerm={searchTerm}
          />
        )}

        {isEditModalOpen && selectedAppointmentData && references && (
          <AppointmentModal
            references={references}
            appointmentData={selectedAppointmentData}
            onClose={closeModal}
            onSave={() => {
              closeModal();
              loadAppointments(currentPage);
            }}
            reloadTable={reloadTable} 
          />
        )}
      </div>
    </div>
  );
};
export default Appointments;

const AppointmentModal = ({ references, appointmentData, onClose, onSave, editMode = false, reloadTable }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [currentState, setCurrentState] = useState('Visualização'); 
  const [attestData, setAttestData] = useState(null);
  const [beneficiaryData, setBeneficiaryData] = useState(null);
  const [attestPhotoUrl, setAttestPhotoUrl] = useState('');

  const handleChangeState = (newState) => {
    setCurrentState(newState);
  };

  const handleCloseOrCancel = () => {
    onClose();
  };

  const handleCancelAppointment = async () => {
    try {
      const response = await putAppointmentCancel(appointmentData.id);

      if (response && response.responseStatus === "ok") {
        console.log('Agendamento cancelado com sucesso!');
        handleCloseOrCancel();
        reloadTable();
      } else {
        console.error('Falha ao cancelar o agendamento:', response);
      }
    } catch (error) {
      console.error('Erro ao cancelar o agendamento:', error);
    }
  };
  

  const loadAppointmentDetails = async (appointmentId, beneficiaryUserId) => {
    try {
      setIsLoading(true);
  
      const [attestResult, beneficiaryResult] = await Promise.all([
        getAttestById(appointmentId),
        beneficiaryUserId ? getUser(beneficiaryUserId) : Promise.resolve(null)
      ]);
  
      if (attestResult?.data) {
        setAttestData(attestResult.data);

        if (attestResult.data.appointment.attestmentPhoto) {
          const photoUrl = await fetchAttestPhoto(attestResult.data.appointment.attestmentPhoto);
          setAttestPhotoUrl(photoUrl);
        }
      } else {
        console.warn('Dados do registro não encontrados.');
      }
  
      if (beneficiaryResult) {
        setBeneficiaryData({
          ...beneficiaryResult,
          user: {
            ...beneficiaryResult.user,
            profile: {
              ...beneficiaryResult.user.profile,
              cpf: beneficiaryResult.user.profile.cpf
                ? beneficiaryResult.user.profile.cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4')
                : '',
            },
          },
        });
      } else if (beneficiaryUserId) {
        console.warn('Dados do beneficiário não encontrados.');
      }
    } catch (err) {
      console.error('Erro ao carregar detalhes do agendamento:', err);
      setError('Erro ao carregar os detalhes do agendamento.');
    } finally {
      setIsLoading(false);
    }
  };
  

  const isEmptyObject = (obj) => !obj || Object.keys(obj).length === 0;

  useEffect(() => {
    if (!isEmptyObject(appointmentData) && appointmentData?.id) {
      loadAppointmentDetails(appointmentData.id, appointmentData.producer.user.id);
    } else {
      setIsLoading(false);
      setError('Dados do registro não encontrados ou inválidos.');
    }
  }, [appointmentData]);

  const exportDivToPDF = (divId, appointmentData) => {
    const input = document.getElementById(divId);
  
    if (!input) {
      console.error('Div não encontrada!');
      return;
    }
  
    const fileName = `Attestment_${appointmentData?.id || 'Unknown'}`; // Nome do arquivo
  
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png'); // Converte o canvas para uma imagem
      const pdf = new jsPDF('p', 'mm', 'a4'); // Cria um PDF no formato A4
  
      // Cálculo para redimensionar a imagem à página A4
      const imgWidth = 210; // Largura da página em mm (A4)
      const pageHeight = 297; // Altura da página em mm (A4)
      const imgHeight = (canvas.height * imgWidth) / canvas.width; // Altura proporcional
      let heightLeft = imgHeight;
  
      let position = 0; // Posição inicial no PDF
  
      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight); // Adiciona a imagem ao PDF
      heightLeft -= pageHeight;
  
      // Se o conteúdo ultrapassar uma página, adiciona páginas extras
      while (heightLeft > 0) {
        position -= pageHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
  
      pdf.save(`${fileName}.pdf`); // Salva o PDF com o nome dinâmico
    });
  };
  

  const renderModalContent = () => {
    switch (currentState) {
      case 'Visualização':
        return (
          <>
            <div className="flex flex-col gap-md w-full h-full px-lg py-sm">
              <Title style='text-green-4'>Visita {appointmentData.status}</Title>
              <div className='flex flex-row w-full h-full gap-md'>
                <div className='flex flex-col w-2/3 gap-sm'>
                  <Body style='text-green-3'>Detalhes</Body>
                  <div className='flex flex-col gap-xs'>
                    <ContentField
                      label='Propriedade'
                      icon='location_away'
                      value={appointmentData.propertyAlias}
                    />
                    <ContentField
                      label='Produtor'
                      icon='person'
                      value={appointmentData.producerName}
                    />
                    <ContentField
                      label='Data'
                      icon='calendar_today'
                      value={appointmentData.scheduledTo}
                    />
                    <ContentField
                      label='Técnico'
                      icon='badge'
                      value={appointmentData.technicianName}
                    />
                    <ContentField
                      label="Tipo de assistência técnica"
                      icon="home_repair_service"
                      value={
                        references.serviceTypes.find(type => type.id === appointmentData.serviceTypeId)?.value || "Tipo não encontrado"
                      }
                    />
                    <ContentField
                      label='Informações adicionais'
                      icon='description'
                      value={appointmentData.observations}
                    />
                  </div>
                </div>
                <div className='flex grow flex-col w-1/3 border-l border-l-gray-1 pl-md gap-sm'>
                  <Body style='text-green-3'>Endereço</Body>
                  <div className='flex flex-col gap-xs'>
                    <div className='flex gap-sm'>
                      <ContentField
                        label='Endereço'
                        icon='short_text'
                        value={appointmentData.address}
                      />
                      <ContentField
                        label='Nº'
                        icon='short_text'
                        value={appointmentData.addressNumber}
                      />
                    </div>
                    <ContentField
                      label='CEP'
                      icon='short_text'
                      value={appointmentData.postalCode}
                    />
                    <div className='flex gap-sm'>
                      <ContentField
                        label='Cidade'
                        icon='short_text'
                        value={appointmentData.city}
                      />
                      <ContentField
                        label='Estado'
                        icon='short_text'
                        value={appointmentData.state}
                      />
                    </div>
                    <ContentField
                      label='Zona UTM'
                      icon='short_text'
                      value={appointmentData.utmZone}
                    />
                    <div className='flex gap-sm'>
                      <ContentField
                        label='X'
                        icon='short_text'
                        value={appointmentData.coordinatesX}
                      />
                      <ContentField
                        label='Y'
                        icon='short_text'
                        value={appointmentData.coordinatesY}
                      />
                    </div>
                    <ContentField
                      label='Complemento'
                      icon='short_text'
                      value={appointmentData.addressComplement}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case 'VisualizacaoRegistro':
        return (
          <div id="attestDiv" className="flex flex-col gap-md w-full h-full px-lg py-sm">
            <div className="flex flex-col justify-between gap-2">
              <Title style='text-green-4'>Registro de visita</Title>
              <Body size="lg">Ateste do(a) Agricultor(a)</Body>
            </div>
            <div className="flex flex-col justify-between gap-6">
              <div className="flex flex-col gap-2">
                <Label size="lg">1. Entidade Executora</Label>
                {appointmentData.producer.account.entity === 'IPAM' ? (
                  <Body size="lg" className="text-gray-3">
                    Instituto de Pesquisa Ambiental da Amazônia
                  </Body>
                ) : (
                  <Body size="lg" className="text-gray-3">
                    Fundação Viver Produzir E Preservar
                  </Body>
                )}
                <div className="flex flex-row gap-2">
                  <Label size="md" className="text-gray-3">
                    CNPJ:
                  </Label>
                  {appointmentData.producer.account.entity === 'IPAM' ? (
                    <Body size="md" className="text-gray-3">
                      00.627.727/00001-01
                    </Body>
                  ) : (
                    <Body size="md" className="text-gray-3">
                      34.887.828/0001-25
                    </Body>
                  )}
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <Label size="lg">2. Técnico(a)</Label>
                <Body size="lg" className="text-gray-3">
                  {appointmentData.technicianName}
                </Body>
              </div>

              <div className="flex flex-col gap-2">
                <Label size="lg">3. Local de Realização da Atividade</Label>
                <Body size="lg" className="text-gray-3">
                  {appointmentData.producer.property.address} {appointmentData.producer.property.addressNumber}, {appointmentData.producer.property.addressComplement}, {appointmentData.producer.property.city}, {appointmentData.producer.property.state} - {appointmentData.producer.property.postalCode}
                </Body>
              </div>

              <div className="flex flex-col gap-2">
                <Label size="lg">4. Produtor(a) / Assistido(a)</Label>
                <Body size="lg" className="text-gray-3">{appointmentData.producer.user.name}</Body>
                <div className="flex flex-row gap-2">
                  <Label size="md" className="text-gray-3">
                    CPF:
                  </Label>
                  <Body size="md" className="text-gray-3">
                    {beneficiaryData.user.profile.cpf}
                  </Body>
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <Label size="lg">5. Serviço do Contrato</Label>
                <Body size="lg" className="text-gray-3">
                  Prestação de ATER através de visita técnica individual
                </Body>
              </div>

              <div className="flex flex-col gap-2">
                <Label size="lg">6. Atividades e assuntos abordados</Label>
                {attestData.activities.map((activity, index) => (
                  <div key={index} className="flex flex-col gap-2">
                    <ContentField
                      label="Atividade produtiva"
                      value={references.productiveSystems.find((systemItem) => systemItem.id === activity.productiveSystemId)?.value}
                      icon="short_text"
                    />
                    <ContentField
                      label="Situação verificada"
                      value={activity.situation}
                      icon="short_text"
                    />
                    <ContentField
                      label="Recomendações técnicas"
                      value={activity.recommendations}
                      icon="short_text"
                    />
                    <div className="h-px bg-gray-1 my-1" />
                  </div>
                ))}
              </div>

              <div className="flex flex-col gap-2">
                <Label size="lg">7. Encaminhamentos e observações técnicas gerais</Label>
                <ContentField
                  label="Recomendações gerais"
                  value={appointmentData.observations}
                  icon="short_text"
                />
              </div>

              <div className="flex flex-col gap-2">
                <Label size="lg">8. Insumos</Label>
                {attestData.products.map((product, index) => (
                  <ContentField
                    key={index}
                    label="Insumo"
                    value={`${references.products.find((productItem) => productItem.id === product.productId)?.value}, ${product.productQty} ${references.products.find((productItem) => productItem.id === product.productId)?.unit}`}
                    icon="short_text"
                  />
                ))}
              </div>

              <div className="flex flex-col gap-2">
                <Label size="lg">9. Foto</Label>
                <img
                  className="max-w-[500px] rounded-sm object-contain object-left"
                  src={attestPhotoUrl}
                  alt="Atest Photo"
                />
              </div>

              <div className="flex flex-col gap-2">
                <Label size="lg">10. Assinatura e Data de Execução</Label>
                <div className="flex flex-row p-2 bg-green-1 rounded-sm">
                  <Body size="md">
                    Assinado por {appointmentData.technicianName} em {new Date(appointmentData.attestedAt).toLocaleString('pt-BR', {year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false,})}
                  </Body>
                </div>
              </div>
            </div>

          </div>
        );
      default:
        return null;
    }
  };

  const getCustomFooter = () => {
    switch (currentState) {
      case 'Visualização':
        return (
          <div className="flex items-center justify-end gap-sm p-sm w-full">
            {appointmentData.status === 'confirmado' && (
              <>
                <ButtonLink
                  text="Desmarcar visita"
                  onClick={() => handleCancelAppointment()}
                />
              </>
            )}
            {appointmentData.status === 'concluído' && (
              <ButtonPrimary
                text="Ver registro"
                onClick={() => handleChangeState('VisualizacaoRegistro')}
              />
            )}
          </div>
        );
      case 'VisualizacaoRegistro':
        return (
          <div className="flex items-center justify-end gap-sm p-sm w-full">
            <ButtonPrimary
              icon="Download"
              text="Exportar PDF"
              onClick={() => exportDivToPDF('attestDiv', appointmentData)}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      title="Visita"
      onClose={handleCloseOrCancel}
      onCancel={handleCloseOrCancel}
      customFooter={getCustomFooter()}
      noPadding
    >
      {isLoading ? (
        <div className="flex items-center justify-center h-full">
          <span>Carregando...</span>
        </div>
      ) : (
        renderModalContent()
      )}
    </Modal>
  );
};
